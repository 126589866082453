import React, { useState } from 'react';
import { Button, Flex, Text } from 'common/styles';
import TextField from 'common/TextField';
import { __t } from 'common/i18n';

type PasswordFormProps = {
  onSubmitPassword: (value: { msisdn: string, password: string; }) => void,
  onRequestPasswordRecover: () => void,
  onRequestOtp: (value: { msisdn: string; }) => void,
  data: {
    msisdn: string,
    loading?: boolean,
  },
};

export default function PasswordForm({
  data, onSubmitPassword, onRequestOtp, onRequestPasswordRecover: handleRequestPasswordRecover,
}: PasswordFormProps) {
  const [password, setPassword] = useState('');

  const handleChange = ({ target: { value } }) => {
    setPassword(value);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    if (password) {
      onSubmitPassword?.({ password, msisdn: data.msisdn });
    }
  };

  return (
    <Flex as="form" direction="column" onSubmit={handleSubmit}>
      <input id="msisdn" defaultValue={data.msisdn} name="msisdn" type="tel" autoComplete="username" hidden />
      <Text align="center" mb={{ xs: 5, md: 6 }}>{__t('form.password.helperText')}</Text>
      <TextField
        autoFocus
        fullWidth
        name="password"
        autoComplete="password"
        type="password"
        value={password}
        onChange={handleChange}
        mb={2}
      />
      <Button type="submit" mb={2} variant="primaryA" disabled={data?.loading || !password} fullWidth>
        {__t('auth.login')}
      </Button>
      <Button
        type="button"
        onClick={() => onRequestOtp({ msisdn: data.msisdn })}
        mb={4}
        disabled={data?.loading}
        fullWidth
      >
        {__t('auth.login.with.otp')}
      </Button>
      <Flex justify="space-between">
        <Text variant="body2" color="weak">{__t('auth.password.forgot_password')}</Text>
        <Button p={0} onClick={handleRequestPasswordRecover} type="button" variant="flat">
          {__t('auth.password.recover_password')}
        </Button>
      </Flex>
    </Flex>
  );
}
