export default function IconSmartTv() {
  return (
    <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1" fill="currentColor">
        <path d="M24 13C24 13.5523 23.5523 14 23 14L1 14C0.447716 14 3.91405e-08 13.5523 8.74228e-08 13L1.1365e-06 0.999998C1.18478e-06 0.447714 0.447717 -2.05901e-06 1 -2.01072e-06L23 -8.74228e-08C23.5523 -3.91406e-08 24 0.447716 24 1L24 13Z" />
      </mask>
      <path d="M24 13C24 13.5523 23.5523 14 23 14L1 14C0.447716 14 3.91405e-08 13.5523 8.74228e-08 13L1.1365e-06 0.999998C1.18478e-06 0.447714 0.447717 -2.05901e-06 1 -2.01072e-06L23 -8.74228e-08C23.5523 -3.91406e-08 24 0.447716 24 1L24 13Z" fill="currentColor" />
      <path d="M23 13L1 13L1 15L23 15L23 13ZM1 13L1 0.999998L-0.999999 0.999998L-1 13L1 13ZM1 0.999998L23 1L23 -1L1 -1L1 0.999998ZM23 1L23 13L25 13L25 1L23 1ZM23 1L23 1L25 1C25 -0.10457 24.1046 -1 23 -1L23 1ZM1 0.999998L1 -1C-0.104569 -1 -0.999999 -0.104571 -0.999999 0.999998L1 0.999998ZM1 13L-1 13C-1 14.1046 -0.104569 15 1 15L1 13ZM23 15C24.1046 15 25 14.1046 25 13L23 13L23 13L23 15Z" fill="currentColor" mask="url(#path-1-inside-1)" />
      <path d="M20 13L21 16" stroke="currentColor" strokeLinecap="round" />
      <path d="M4 13L3 16" stroke="currentColor" strokeLinecap="round" />
      <path d="M9.5 3.99997H3.5" stroke="#1C1C1C" strokeWidth="2" strokeLinecap="round" />
      <path d="M6.5 3.99997V9.99997" stroke="#1C1C1C" strokeWidth="2" strokeLinecap="round" />
      <path d="M13.5 3.99997L17 9.99997" stroke="#1C1C1C" strokeWidth="2" strokeLinecap="round" />
      <path d="M20.5 3.99997L17.0269 10.0156" stroke="#1C1C1C" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}
