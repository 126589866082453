import { __t } from 'common/i18n';
import { Button, Flex, Icon, Text } from 'common/styles';
import React, { FormEvent, useState } from 'react';
import { ActiveSession, ClientPlatform } from '../apiService';
import Table, { Column } from 'common/Table';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { IconAndroid, IconSmartTv, IconWeb } from 'common/icons';
import Checkbox from 'common/Checkbox';

const columns: Column[] = [
  {
    title: '',
    field: 'checkbox',
  },
  {
    title: 'auth.sessions.device',
    field: 'device',
    align: 'right',
  },
  {
    title: 'auth.sessions.ip_address',
    field: 'ip',
    align: 'right',
  },
];

interface RowData {
  checkbox: ReactJSXElement;
  device: ReactJSXElement;
  ip: ReactJSXElement;
}

type SessionListProps = {
  sessions?: ActiveSession[],
  onKillSessions: (sessions: ActiveSession[]) => void,
};

const getContentIcon = (type: ClientPlatform) => {
  switch (type) {
    case 'web_tv': case 'android_tv':
      return <IconSmartTv />;
    case 'android':
      return <IconAndroid />;
    case 'i_os':
      return <IconAndroid />;
    case 'web':
      return <IconWeb />;
    default:
      return null;
  }
};

export default function SessionKiller({ sessions = [], onKillSessions }: SessionListProps) {
  const [selected, setSelected] = useState<ActiveSession[]>([]);

  const handleChange = (session: ActiveSession) => {
    setSelected((prev) => {
      const index = prev.findIndex((x) => x.key === session.key);
      if (index === -1) return [...prev, session];

      const newSessions = [...prev];
      newSessions.splice(index, 1);
      return newSessions;
    });
  };

  const handleSubmit = (e?: FormEvent) => {
    e?.preventDefault();
    onKillSessions(selected);
  };

  const sessionsData: RowData[] = sessions.map((session) => ({
    checkbox: (
      <Checkbox
        name={session.key}
        checked={!!selected.find(({ key }) => key === session.key)}
        onChange={() => handleChange(session)}
      />
    ),
    device: <Text variant="body1"><Icon ml={1} style={{ verticalAlign: 'baseline' }} size="large">{session.device?.platform && getContentIcon(session.device?.platform)}</Icon>{session.device?.brand}</Text>,
    ip: <Text variant="body2">{session.ip}</Text>,
  }));

  return (
    <Flex as="form" direction="column" onSubmit={handleSubmit}>
      <Text align="center" weight="bold">{__t('errors.session.limit')}</Text>
      <Text align="center" variant="body2" mt={1} mb={{ xs: 5, md: 6 }}>{__t('errors.session.remove_one_device')}</Text>

      { sessionsData && <Table rows={sessionsData} columns={columns} />}

      <Button variant="primaryA" fullWidth disabled={!selected || selected.length < 1}>
        {__t('auth.sessions.submit')}
      </Button>
    </Flex>
  );
}
