/* eslint-disable no-restricted-globals */
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, Icon, Link, Text } from 'common/styles';
import { __t } from 'common/i18n';
import { IconEdit } from 'common/icons';
import Timer from 'common/Timer';
import TimerProgressBar from 'common/TimerProgressBar';
import MaskedInput from 'common/MaskedTextField';
import { getLatinDigits } from 'common/utils';

type requestedOtpType = {
  msisdn: string,
  newUser: boolean,
};
type OtpFormProps = {
  onSubmitOtp: (value: { otp: string; }) => void,
  onRequestOtp: ({ msisdn, newUser }: requestedOtpType) => void,
  data: {
    msisdn: string,
    newUser: boolean,
    loading?: boolean,
    otpTtl: string
  },
};

export default function OtpForm({ data, onSubmitOtp, onRequestOtp }: OtpFormProps) {
  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState<number>(0);

  const { otpTtl } = data;

  const otpTtlToSeconds = useMemo((): number => {
    if (otpTtl) {
      const hms = otpTtl;
      const a = hms.split(':');
      const seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
      return seconds;
    } return 60;
  }, [otpTtl]);

  useEffect(() => {
    if (data) setTimer(otpTtlToSeconds);
  }, [data, otpTtlToSeconds]);

  const handleChange = ({ target: { value } }) => {
    if (value.length > 5) return;

    const _value = getLatinDigits(value);
    if (isNaN(Number(_value))) return;

    setOtp(_value);
    if (_value.length === 5) {
      onSubmitOtp({ otp: _value });
    }
  };

  const handleSubmit = async (e) => {
    e && e.preventDefault();
    if (onSubmitOtp && otp) {
      onSubmitOtp({ otp });
    }
  };

  const handleRequestOtp = (e) => {
    e.preventDefault();
    onRequestOtp({ msisdn: data.msisdn, newUser: data.newUser });
    handleFinish();
    setOtp('');
  };

  const handleFinish = () => {
    setTimer(0);
  };

  return (
    <Flex as="form" align="center" direction="column" onSubmit={handleSubmit}>
      <Box>
        <Text align="center" mb={2}>{__t('form.otp.helperText')}</Text>
        <Flex justify="space-between" align="center" mb={{ xs: 5, md: 6 }}>
          {data.msisdn}
          <Link href="/login" replace>
            <Icon cursor="pointer"><IconEdit /></Icon> {__t('auth.login.change_number')}
          </Link>
        </Flex>
      </Box>
      <MaskedInput
        mask="99999"
        align="center"
        letterSpacing="large"
        autoFocus
        fullWidth
        name="otp"
        type="tel"
        value={otp}
        onChange={handleChange}
        mb={2}
      />
      <Button type="submit" variant="primaryA" disabled={data?.loading || !otp} fullWidth>
        {__t('common.submit')}
      </Button>
      <Flex justify="space-between" mt={4} mb={1} fullWidth>
        <div>{!!timer && <Timer time={timer} onFinish={handleFinish} />}</div>
        {!timer
          ? <Link href="#" onClick={handleRequestOtp}>{__t('common.resend')}</Link>
          : <Text variant="body2" color="weak">{__t('common.resend')}</Text>
        }
      </Flex>
      <TimerProgressBar duration={timer} />
    </Flex>
  );
}
