import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import {
  ActiveSession,
  CreateAccessTokenResponse,
  getOtpCode,
  getUserApproaches,
  killOtherSessionsAndLogin,
  loginByOtpCode,
  loginByPassword, popUpMessaging,
  setPasswordForced,
} from 'common/apiService';
import { actionLogin, useDispatch, useUser } from 'common/CacheProvider';
import MobileForm from './MobileForm';
import OtpForm from './OtpForm';
import SessionKiller from './SessionKiller';
import ControlledView from '../ControlledView';
import PasswordForm from './PasswordForm';
import SetPasswordForm from '../SetPasswordForm';
import { __t } from 'common/i18n';
import { Button, Icon, Paper, Box } from 'common/styles';
import { IconAngleRight } from 'common/icons';
import MiniLayout from 'common/Layout/MiniLayout';
import { SystemMessage } from 'common/Header/SystemMessage';
import lscache from 'lscache';
import { validateRedirectRoutes } from '../utils';

export const loginRoutes = ['otp', 'password', 'sessions', 'set-password', 'recover-otp', 'recover-password'] as const;
export const registrationRoutes = ['otp', 'set-password'] as const;
export type LoginRoute = typeof loginRoutes[number] | '';
export type RegistrationRoute = typeof registrationRoutes[number] | '';

const initState = {
  msisdn: '',
  loading: false,
  hasPassword: false,
  newUser: false,
  requestedOtp: false,
  sessions: undefined as ActiveSession[] | undefined,
  temp_token: '' as string | undefined,
  enableSetPassword: false,
  recoverPassword: false,
  otpTtl: '',
};

export type LoginPageProps = {
  isRegisterRoute: true,
  subRoute: RegistrationRoute,
} | {
  isRegisterRoute: false,
  subRoute?: LoginRoute,
};

const BackButton = ({ loading, onClick: handleClick }) => (
  <Box
    style={{ height: '1rem' }}
    mt={{ xs: '-.75rem', md: '-1.5rem' }}
    mb={{ xs: '1.5rem', md: '2rem' }}
    mr={{ xs: '-.5rem', md: '-1rem' }}
  >
    <Button p={0} disabled={loading} onClick={handleClick} type="button" variant="flat">
      <Icon cursor="pointer"><IconAngleRight /></Icon>{__t('common.back')}
    </Button>
  </Box>
);

const pageEvents = {
  '/login/password': 'login-password',
  '/register/set-password': 'register',
  '/login/set-password': 'set-password',
  '/login/recover-password': 'recover-password',
  '/login/sessions': 'sessions',
  '/register/otp': 'register-otp',
  '/login/recover-otp': 'recover-otp',
  '/login/otp': 'otp',
  '/login': 'login',
};

export default function LoginPage({ subRoute = '', isRegisterRoute }: LoginPageProps) {
  const { push } = useRouter();
  const [state, setState] = useState(initState);
  const { authorized, loaded } = useUser();
  const dispatch = useDispatch();
  const searchRef = useRef<string>('');
  const [system_message, setSystemMessage] = useState<SystemMessage | null>(null);

  useEffect(() => {
    if (typeof window === 'object') {
      setSystemMessage(lscache.get('system_message'));
      window.addEventListener('storage', handleSystemMessage);
      searchRef.current = window.location.search;
    }

    return () => {
      window.removeEventListener('storage', handleSystemMessage);
    };
  }, []);

  const handleSystemMessage = () => {
    setSystemMessage(lscache.get('system_message'));
  };

  const redirect = (page: string) => {
    const url = page + searchRef.current;
    push(url).then(() => {
      (window as any)?.dataLayer?.push({ event: pageEvents[page] });
    });
  };

  const prevSubRoute = useRef(subRoute);
  useEffect(() => {
    if (prevSubRoute.current !== subRoute) {
      prevSubRoute.current = subRoute;
      if (subRoute === '') setState((prev) => ({ ...initState, msisdn: prev.msisdn })); // reset state when navigating to root route
    }
  }, [subRoute]);

  const handleError = () => {
    setState((prev) => ({ ...prev, loading: false }));
  };

  const handleSubmitMobile = ({ msisdn = '' }) => {
    setState((prev) => ({ ...prev, loading: true }));
    getUserApproaches(msisdn).then(({ data: { approaches, flag } }) => {
      const hasPassword = approaches.includes('password');
      const newUser = flag === 'new_user';
      setState((prev) => ({ ...prev, loading: false, msisdn, hasPassword, newUser }));
      if (!newUser && hasPassword) redirect('/login/password');
      else handleRequestOtp({ msisdn, newUser });
    }).catch(() => {
      handleError();
    });
  };

  const handleGetPopUpMessage = () => {
    popUpMessaging().then(({ data }) => {
      if (data) {
        lscache.set('system_message', {
          message: data?.text,
          isClosedBefore: false,
          is_dismissable: data.is_dismissable,
          type: data?.type,
        });
        window.dispatchEvent(new Event('storage'));
      } else {
        if (system_message) {
          lscache.set('system_message', null);
        }
      }
    }).catch(() => {
      lscache.set('system_message', null);
    });
  };

  const handleSuccessLogin = ({ data: { user, access_token } }: { data: CreateAccessTokenResponse; }) => {
    if (!state.hasPassword || state.recoverPassword || state.newUser)
      setState((prev) => ({ ...prev, enableSetPassword: true }));
    dispatch(actionLogin({ access_token, user }));
    handleGetPopUpMessage();
    if (state.newUser) redirect('/register/set-password');
    else if (!state.hasPassword) redirect('/login/set-password');
    else if (state.recoverPassword) redirect('/login/recover-password');
  };

  const handleCheckSessions = ({ data }: { data: CreateAccessTokenResponse; }) => {
    setState((prev) => ({ ...prev, loading: false }));
    const { access_token, other_sessions } = data;
    if (!other_sessions) {
      handleSuccessLogin({ data });
    } else {
      setState((prev) => ({ ...prev, sessions: other_sessions, temp_token: access_token }));
      redirect('/login/sessions');
    }
  };

  const handleSubmitPassword = ({ password = '', msisdn = '' }) => {
    setState((prev) => ({ ...prev, loading: true }));
    loginByPassword({ msisdn, password }).then(handleCheckSessions).catch(() => {
      handleError();
    });
  };

  const handleSubmitOtp = ({ otp = '' }) => {
    setState((prev) => ({ ...prev, loading: true }));
    loginByOtpCode({ msisdn: state.msisdn, otp }).then(handleCheckSessions).catch(() => {
      // handleError();
      setState((prev) => ({ ...prev, loading: false, otpTtl: '00:00:00' }));
    });
  };

  const handleRequestOtp = ({ msisdn = '', recoverPassword = false, newUser = false }) => {
    setState((prev) => ({ ...prev, loading: true, recoverPassword }));
    getOtpCode({ msisdn }).then((res) => {
      setState((prev) => ({ ...prev, loading: false, requestedOtp: true, otpTtl: res.data.ttl }));
      if (newUser) redirect('/register/otp');
      else if (recoverPassword) redirect('/login/recover-otp');
      else redirect('/login/otp');
    }).catch(() => {
      handleError();
    });
  };

  const handleRequestPasswordRecover = () => handleRequestOtp({ msisdn: state.msisdn, recoverPassword: true });

  const handleKillSessions = (sessions: ActiveSession[]) => {
    killOtherSessionsAndLogin({
      sessions,
      msisdn: state.msisdn,
      access_token: state.temp_token ?? '',
    }).then(handleSuccessLogin).catch(() => {
      handleError();
    });
  };

  const handleSkipSetPassword = () => setState((prev) => ({ ...prev, enableSetPassword: false }));

  const handleSubmitNewPassword = ({ password }: { password: string; }) => {
    setState((prev) => ({ ...prev, loading: true }));
    setPasswordForced({ password }).then(() => {
      setState((prev) => ({ ...prev, loading: false, enableSetPassword: false }));
    }).catch(() => {
      handleError();
    });
  };

  const handleBack = () => redirect('/login');

  const callbacks = {
    onSubmitMobile: handleSubmitMobile,
    onSubmitPassword: handleSubmitPassword,
    onRequestBack: handleBack,
    onRequestOtp: handleRequestOtp,
    onSubmitOtp: handleSubmitOtp,
    onSubmitNewPassword: handleSubmitNewPassword,
    onRequestPasswordRecover: handleRequestPasswordRecover,
    onSkipSetPassword: handleSkipSetPassword,
    onKillSessions: handleKillSessions,
  };

  return (
    <MiniLayout>
      <Head>
        <title> {isRegisterRoute ? __t('auth.signup') : __t('auth.login')} | {__t('siteName')}</title>
      </Head>
      <Paper
        py={{ xs: 3, md: 6 }}
        px={{ xs: 2, md: 4 }}
        style={{ width: '100%', maxWidth: subRoute === 'sessions' ? '40rem' : '25rem' }}
      >
        <ControlledView in={loaded} triggerRedirect={authorized && !state.enableSetPassword}
          redirectUrl={getRedirectFromSearch(searchRef.current) || '/'}>
          <ControlledView in={subRoute === ''}>
            <MobileForm data={state} {...callbacks} />
          </ControlledView>

          <ControlledView in={subRoute === 'password'} triggerRedirect={!state.hasPassword}>
            <BackButton loading={state.loading} onClick={() => callbacks.onRequestBack()} />
            <PasswordForm data={state} {...callbacks} />
          </ControlledView>

          <ControlledView in={subRoute === 'otp' || subRoute === 'recover-otp'} triggerRedirect={!state.requestedOtp}>
            <BackButton loading={state.loading} onClick={() => callbacks.onRequestBack()} />
            <OtpForm data={state} {...callbacks} />
          </ControlledView>

          <ControlledView in={subRoute === 'set-password' || subRoute === 'recover-password'}
            triggerRedirect={!authorized || !state.enableSetPassword}>
            <SetPasswordForm data={state} {...callbacks} />
          </ControlledView>
          <ControlledView in={subRoute === 'sessions'} triggerRedirect={!state.sessions}>
            <SessionKiller sessions={state?.sessions} {...callbacks} />
          </ControlledView>
        </ControlledView>
      </Paper>
    </MiniLayout>
  );
}

const exists = (char: string, string: string) => string.indexOf(char) > -1;

export function getRedirectFromSearch(search: string): string {
  let redirect = decodeURIComponent(search.replace(/\?redirect=/, ''));
  if (exists('&', redirect) && !exists('?', redirect)) redirect = redirect.replace('&', '?');
  return validateRedirectRoutes(redirect) ? redirect : '/';
}
