import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Button, Flex, Text } from 'common/styles';
import { __t } from 'common/i18n';
import { validate, ValidationError, ValidationRule } from 'common/utils';
import MaskedInput from 'common/MaskedTextField';

type MobileFormProps = {
  onSubmitMobile: (value: { msisdn: string; }) => void,
  data: {
    msisdn: string,
    loading?: boolean,
  },
};

const validation: ValidationRule[] = [
  ['isPhone', 'errors.format'],
  ['maxlength', 'errors.maxlength', { lengthL: 11 }],
];

export default function MobileForm({ data, onSubmitMobile }: MobileFormProps) {
  const [msisdn, setMsisdn] = useState(data.msisdn);
  const [error, setError] = useState<ValidationError | null>(null);
  const isValid = !validate(msisdn, validation);

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setError(null);
    setMsisdn(value);
  };

  const handleSubmit = (e: FormEvent) => {
    e?.preventDefault();
    const error = validate(msisdn, validation);
    if (error) { setError(error); return; }

    onSubmitMobile?.({ msisdn });
  };

  return (
    <Flex as="form" direction="column" onSubmit={handleSubmit}>
      <Text variant="h1" align="center" mb={{ xs: 2, md: 3 }}>{__t('auth.login')}</Text>
      <Text align="center" mb={{ xs: 5, md: 6 }}>{__t('form.mobile.helperText')}</Text>
      <MaskedInput
        mask="99999999999"
        letterSpacing="large"
        align="center"
        dir="ltr"
        autoFocus
        fullWidth
        name="msisdn"
        autoComplete="tel"
        type="tel"
        maxLength={11}
        value={msisdn}
        error={error}
        onChange={handleChange}
        mb={{ xs: 2, md: 3 }}
      />
      <Button
        type="submit"
        variant="primaryA"
        disabled={data?.loading || !msisdn || !isValid}
        fullWidth
      >
        {__t('auth.login_or_register')}
      </Button>
    </Flex>
  );
}
