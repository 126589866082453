import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from './styles';

const widthAnimation = keyframes`
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
`;

const TimerProgressBar = styled(Box)<{ duration: number }>(({ theme, duration }) => ({
  height: '.125rem',
  width: '100%',
  backgroundColor: theme.rgba(theme.palette.foreground[500][0], 0.5),
  '&::after': {
    content: '""',
    display: 'block',
    height: '100%',
    transform: 'scaleX(0)',
    transformOrigin: 'right',
    backgroundColor: theme.palette.background[500][1],
    animation: duration && `${widthAnimation} ${duration + 1}s linear`,
  },
}));

export default TimerProgressBar;
