import styled from '@emotion/styled';
import { IconCheck } from './icons';

const Container = styled.div(({ theme }) => ({
  '& input': {
    display: 'none',
    cursor: 'pointer',
  },
  '& label': {
    cursor: 'pointer',
  },
  '& input:checked + label': {
    backgroundColor: theme.palette.foreground[900][0],
    svg: {
      opacity: 1,
    },
  },
}));

const CheckboxLabel = styled.label(({ theme }) => {
  const [fg, bg] = theme.palette.foreground[900];

  return ({
    display: 'inline-block',
    width: theme.spacing(3),
    height: theme.spacing(3),
    border: `1px solid ${fg}`,
    borderRadius: theme.radius.default,
    transition: 'background-color .3s ease',
    svg: {
      color: bg,
      opacity: 0,
    },
  });
});

export default function Checkbox({
  name,
  checked,
  onChange: handleChange,
}: {
  name?: string;
  checked: boolean;
  onChange: () => void;
}) {
  return (
    <Container>
      <input checked={checked} type="checkbox" id={name} onChange={handleChange} />
      <CheckboxLabel htmlFor={name}>
        <IconCheck />
      </CheckboxLabel>
    </Container>
  );
}
