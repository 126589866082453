import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Spinner, Text } from './styles';

type ControlledViewProps = {
  in: boolean,
  triggerRedirect?: boolean,
  children?: React.ReactNode,
  fallback?: React.ReactNode,
  redirectUrl?: string,
};

const Loading = () => <Text variant="h2" m={3} align="center"><Spinner block /></Text>;

export default function ControlledView({
  in: entered, triggerRedirect, children, fallback = <Loading />, redirectUrl = '/login',
}: ControlledViewProps) {
  const { replace } = useRouter();
  const router = useRouter();
  useEffect(() => {
    if (entered && triggerRedirect) {
      if (router.asPath.includes('/tv')) {
        replace(redirectUrl);
      } else {
        replace((redirectUrl && redirectUrl !== '/profile' && redirectUrl !== '/choose' && !redirectUrl.includes('/user/edit')) ? `/profile?redirect=${redirectUrl}` : '/profile');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entered, replace, triggerRedirect]);

  if (!entered) return null;
  if (!triggerRedirect) return <>{children}</>;
  return <>{fallback}</>;
}
