export default function IconAndroid() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" viewBox="0 0 20 25" fill="currentColor">
      <path d="M12.5007 24.381C13.3015 24.381 13.9999 23.6814 13.9999 22.8791V19.3715H15.0009C15.6016 19.3715 16.0019 18.9703 16.0019 18.3686V8.35034H3.99927V18.3687C3.99927 18.9704 4.39967 19.3716 5.00028 19.3716H6.00137V22.8792C6.00137 23.6815 6.69956 24.3811 7.50053 24.3811C8.30126 24.3811 8.99953 23.6815 8.99953 22.8792V19.3716H11.0016V22.8792C11.0016 23.6814 11.6999 24.381 12.5007 24.381Z" fill="currentColor" />
      <path d="M18.5001 18.3689C19.3009 18.3689 19.9991 17.6693 19.9991 16.8669V9.85165C19.9991 9.05359 19.3009 8.35034 18.5001 8.35034C17.6992 8.35034 17.001 9.05359 17.001 9.85165V16.8669C17.001 17.6692 17.6992 18.3689 18.5001 18.3689Z" fill="currentColor" />
      <path d="M1.499 18.3689C2.2999 18.3689 2.99817 17.6693 2.99817 16.8669V9.85165C2.99817 9.05359 2.29998 8.35034 1.499 8.35034C0.698193 8.35034 0 9.05359 0 9.85165V16.8669C0 17.6692 0.698193 18.3689 1.499 18.3689Z" fill="currentColor" />
      <path d="M14.8006 0.539756C14.6005 0.337985 14.3025 0.337985 14.1024 0.539756L12.7598 1.88033L12.6979 1.9423C11.9018 1.54292 11.0092 1.34348 10.0152 1.34155C10.0104 1.34155 10.0056 1.34139 10.0007 1.34139H10.0005C9.99551 1.34139 9.99087 1.34155 9.98583 1.34155C8.99194 1.34348 8.09938 1.54292 7.30336 1.9423L7.24127 1.88033L5.89875 0.539756C5.69847 0.337985 5.40068 0.337985 5.20056 0.539756C5.00028 0.740406 5.00028 1.03818 5.20056 1.23867L6.49928 2.54001C6.08081 2.81993 5.70135 3.16246 5.37135 3.55423C4.58133 4.49238 4.07743 5.71333 4.00814 7.03621C4.0075 7.04998 4.00614 7.0636 4.0055 7.07737C4.00127 7.16744 3.99927 7.258 3.99927 7.34888H16.0019C16.0019 7.258 15.9997 7.16744 15.9957 7.07737C15.995 7.0636 15.9937 7.04998 15.9929 7.03621C15.9237 5.71333 15.4197 4.4923 14.6296 3.55431C14.2998 3.16253 13.9202 2.82001 13.5017 2.54009L14.8006 1.23875C15.0009 1.03818 15.0009 0.740406 14.8006 0.539756ZM7.49861 5.59788C7.08446 5.59788 6.74871 5.26151 6.74871 4.8466C6.74871 4.43169 7.08446 4.09532 7.49861 4.09532C7.91276 4.09532 8.24851 4.43169 8.24851 4.8466C8.24851 5.26151 7.91276 5.59788 7.49861 5.59788ZM12.5025 5.59788C12.0884 5.59788 11.7526 5.26151 11.7526 4.8466C11.7526 4.43169 12.0884 4.09532 12.5025 4.09532C12.9167 4.09532 13.2524 4.43169 13.2524 4.8466C13.2524 5.26151 12.9167 5.59788 12.5025 5.59788Z" fill="currentColor" />
    </svg>
  );
}
