import React, { useEffect, useState } from 'react';
import { Button, Flex, Text } from 'common/styles';
import TextField from 'common/TextField';
import { __t } from 'common/i18n';
import { validate, validateAll, ValidationDefinition, ValidationError } from './utils';

type SetPasswordFormProps = {
  onSubmitNewPassword: (value: { password: string, msisdn: string, }) => void,
  onSkipSetPassword?: () => void,
  data: {
    msisdn: string,
    loading?: boolean,
    success?: boolean,
  },
};

type StateType<T> = { [key: string]: T | string }
const initState = {
  password: '',
  confirm_password: '',
};

export default function SetPasswordForm({
  data, onSubmitNewPassword, onSkipSetPassword: handleSkipSetPassword,
}: SetPasswordFormProps) {
  const [state, setState] = useState<StateType<string>>(initState);
  const [errors, setErrors] = useState<StateType<ValidationError>>(initState);

  const validations: ValidationDefinition = {
    password: [
      ['required', 'errors.required'],
      ['minlength', 'errors.minlength', { length: 6 }],
    ],
    confirm_password: [
      ['required', 'errors.required'],
      ['equalTo', 'error.password.mismatch', { _: state.password }],
    ],
  };

  const handleChange = ({ target: { value, name } }) => {
    setErrors(initState);
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleBlur = ({ target: { value, name } }) => {
    setErrors((prev) => ({
      ...prev,
      [name]: validate(value, validations[name]),
    }));
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    const [isValid, errors] = validateAll({ ...state }, validations);
    setErrors(errors);
    if (!isValid) return;

    onSubmitNewPassword({ password: state.password, msisdn: data.msisdn });
  };

  useEffect(() => {
    data.success && setState(initState);
  }, [data.success]);

  return (
    <Flex as="form" direction="column" onSubmit={handleSubmit}>
      <Text mt={2} mb={4} align="center">{__t('auth.password.set.helper')}</Text>
      <input id="msisdn" defaultValue={data.msisdn} name="msisdn" type="tel" autoComplete="username" hidden />
      <TextField
        autoFocus
        fullWidth
        name="password"
        placeholder={__t('auth.password.new_password')}
        autoComplete="new-password"
        type="password"
        value={state.password}
        error={errors.password}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <TextField
        fullWidth
        name="confirm_password"
        autoComplete="new-password"
        placeholder={__t('auth.password.password_confirm')}
        type="password"
        value={state.confirm_password}
        error={errors.confirm_password}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Button mt={2} mb={2} type="submit" variant="primaryA" disabled={data?.loading} fullWidth>
        {__t('common.submit')}
      </Button>
      {handleSkipSetPassword && (
        <Button type="button" onClick={handleSkipSetPassword} variant="flat" disabled={data?.loading} fullWidth>
          {__t('auth.password.create_later')}
        </Button>
      )}
    </Flex>
  );
}
